import { dispatch } from "../../Store";
import {
  setDropdownId,
  setIsBurgerActive,
  setIsDropdownActive,
  setIsLoaded,
  setIsMenuActive,
  setPopNotACult,
  setPopShareTourExperience,
  setPopUnreleasedTrack,
  setPresavePopupOpen,
  setShowDragLeftRightIndicator,
  setShowOption,
  setSubmitType,
  setShowTour,
} from "./Global.slice";

export default class GlobalService {
  setIsBurgerActive(value: boolean) {
    dispatch(setIsBurgerActive(value));
  }

  setIsLoaded(payload: boolean) {
    dispatch(setIsLoaded(payload));
  }

  setIsDropdownActive(payload: number) {
    dispatch(setIsDropdownActive(payload));
  }

  setDropdownId(payload: number) {
    dispatch(setDropdownId(payload));
  }

  setIsMenuActive(payload: boolean) {
    dispatch(setIsMenuActive(payload));
  }

  setPopShareTourExperience(payload: boolean) {
    dispatch(setPopShareTourExperience(payload));
  }

  setPopNotACult(payload: boolean) {
    dispatch(setPopNotACult(payload));
  }

  setPopUnreleasedTrack(payload: boolean) {
    dispatch(setPopUnreleasedTrack(payload));
  }

  setShowDragLeftRightIndicator(payload: boolean) {
    dispatch(setShowDragLeftRightIndicator(payload));
  }
  setPresavePopupOpen(payload: boolean) {
    dispatch(setPresavePopupOpen(payload));
  }
  setSubmitType(payload: string) {
    dispatch(setSubmitType(payload));
  }
  setShowOption(payload: boolean) {
    dispatch(setShowOption(payload));
  }
  setShowTour(payload: boolean) {
    dispatch(setShowTour(payload));
  }
}
