import useGlobalService from "../features/Global/useGlobalService";
import useSpotifyService from "../features/Spotify/useSpotifyService";
import { useGlobalSelector } from "../features/useSelector";
import { isMobile } from "../constants";

export default function ClickableButtons() {
  const { setPopupSetlist } = useSpotifyService();
  const {
    setShowOption,
    setPopNotACult,
    setPopUnreleasedTrack,
    setShowDragLeftRightIndicator,
    setShowTour,
  } = useGlobalService();
  const { presavePopupOpen } = useGlobalSelector();

  return (
    <>
      <div className={`${isMobile ? "fixed" : "absolute"} z-10 flex justify-between w-full gap-3 lg:gap-5 top-5 px-4 lg:px-6 text-black font-bold ${presavePopupOpen ? "blur-[10px]" : "blur-0"}`}>
        <button
          onClick={() => {
            setShowTour(true);
            setShowDragLeftRightIndicator(false);
          }}
          className="uppercase text-xl btn-text"
        >
          Tour
        </button>
        <button
          onClick={() => {
            window.open('https://store.rocnation.com/products/saint-jhn-festival-season');
          }}
          className="uppercase text-xl btn-text"
        >
          Shop
        </button>
      </div>
      {/* <div className="absolute left-[391px] top-[168px] lg:left-[28%] lg:top-[24%]">
        <button
          onClick={() => {
            setPopUnreleasedTrack(true);
            setShowDragLeftRightIndicator(false);
          }}
          className="btn-clickable"
        />
      </div> */}
      <div className="absolute left-[975px] top-[400px] lg:left-[70.4%] lg:top-[63.5%]">
        <button
          onClick={() => {
            setShowOption(true);
            setShowDragLeftRightIndicator(false);
          }}
          className="btn-clickable"
        />
      </div>
      <div className="absolute left-[692px] top-[86px] lg:left-[49%] lg:top-[12.5%]">
        <button
          onClick={() => {
            setPopNotACult(true);
            setShowDragLeftRightIndicator(false);
          }}
          className="btn-clickable"
        />
      </div>
      <div className="absolute left-[1046px] top-[200px] lg:left-[74.4%] lg:top-[29.5%]">
        <button
          onClick={() => {
            setPopupSetlist(true);
            setShowDragLeftRightIndicator(false);
          }}
          className="btn-clickable"
        />
      </div>
    </>
  );
}
