import { useGlobalSelector } from "../../features/useSelector";
import Popup from "../Popup";
import useGlobalService from "../../features/Global/useGlobalService";
import BGIMG from "../../assets/images/festival season BG 1080.png";
import { useRef, useEffect } from "react";
import useScriptService from "../../hooks/useScriptService";

export default function ShowTours() {
    useScriptService({ url: "https://widgetv3.bandsintown.com/main.min.js" });
    const { setShowTour } = useGlobalService();
    const { showTour } = useGlobalSelector();
    const tourDiv: any = useRef(null);

    useEffect(() => {
        if (showTour) {
            // getTours();
        }
    }, [showTour]);

    async function getTours() {
        const response = await fetch('https://rest.bandsintown.com/artists/id_13682071/events/?app_id=e0132e5e7acb064b84155fa2c2460f6a');
        const json = await response.json();
        const tourContainer: any = tourDiv.current as HTMLDivElement;
        let tours: any = {};

        // const mtop = Math.ceil(json.length/2);
        // tourContainer.style.marginTop = "-" + mtop + "rem";

        console.log(json.length);

        while (tourContainer.hasChildNodes()) {
            tourContainer.removeChild(tourContainer.firstChild);
        }

        if (json.length > 0) {
            if (json.length > 12) {
                tourContainer.style.overflowX = 'hidden';
                tourContainer.style.overflowY = 'auto';
            }

            json.map((tour: any, idx: any) => {
                let tDate = new Date(tour.datetime);
                let mons = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                tours[idx] = {
                    "date": mons[tDate.getMonth()] + " " + tDate.getDate(),
                    "location": tour.venue.location,
                    "venue": tour.venue.name,
                    "url": tour.offers.url
                }

                const node = document.createElement("div");
                const content = document.createTextNode(mons[tDate.getMonth()] + " " + tDate.getDate() + " " + tour.venue.location + " - " + tour.venue.name);
                node.appendChild(content);
                node.className = "my-1 cursor-pointer font-secondary text-black text-md lg:text-2xl";
                node.onclick = () => {
                    window.open("https://www.bandsintown.com/t/" + tour.id + "?app_id=e0132e5e7acb064b84155fa2c2460f6a&came_from=267&utm_medium=api&utm_source=public_api&utm_campaign=ticket");
                }
                tourContainer.appendChild(node);
            });
        } else {
            const node = document.createElement("div");
            const content = document.createTextNode("TBA");
            node.appendChild(content);
            node.className = "my-3 uppercase text-7xl font-secondary text-black";
            tourContainer.appendChild(node);
        }
    }

    return (
        <Popup
            open={showTour}
            setOpen={setShowTour}
            className={`min-h-max lg:w-[65%] 3xl:w-[55%] border border-black flex flex-col`}
        >
            <img src={BGIMG} className="absolute object-cover w-full h-full" />
            <h2 className="relative z-1 font-bold text-4xl lg:text-4xl font-secondary text-black text-center mt-4 mb-2 lg:mt-8 lg:mb-4 uppercase">Tour dates</h2>
            <div ref={tourDiv} className="relative z-1 h-full flex flex-col justify-start items-center mb-4 lg:mb-8 min-h-20 max-h-[30rem] lg:max-h-[33rem] overflow-x-hidden overflow-y-auto px-0 lg:pl-8 lg:pr-5">
                <a className="bit-widget-initializer"
                    
                    data-artist-name="id_13682071"
                    
                    data-events-to-display=""
                    data-background-color="rgba(255,255,255,0)"
                    data-separator-color="rgba(221,221,221,0)"
                    data-text-color="rgba(0,0,0,1)"
                    data-font="Scribbles"
                    data-auto-style="true"
                    
                    data-button-label-capitalization="uppercase"
                    data-header-capitalization="uppercase"
                    data-location-capitalization="uppercase"
                    data-venue-capitalization="uppercase"
                    data-display-local-dates="false"
                    data-local-dates-position="tab"
                    data-display-past-dates="false"
                    data-display-details="false"
                    data-display-lineup="false"
                    data-display-start-time="false"
                    data-social-share-icon="false"
                    data-display-limit="all"
                    
                    data-date-format="MMM. D, YYYY"
                    data-date-orientation="horizontal"
                    data-date-border-color="#4A4A4A"
                    data-date-border-width="1px"
                    data-date-capitalization="capitalize"
                    data-date-border-radius="10px"
                    
                    data-event-ticket-cta-size="medium"
                    data-event-custom-ticket-text=""
                    data-event-ticket-text="TICKETS"
                    data-event-ticket-icon="false"
                    data-event-ticket-cta-text-color="rgba(255,255,255,1)"
                    data-event-ticket-cta-bg-color="rgba(74,74,74,1)"
                    data-event-ticket-cta-border-color="rgba(74,74,74,1)"
                    data-event-ticket-cta-border-width="0px"
                    data-event-ticket-cta-border-radius="2px"
                    
                    data-sold-out-button-text-color="rgba(255,255,255,1)"
                    data-sold-out-button-background-color="rgba(74,74,74,1)"
                    data-sold-out-button-border-color="rgba(74,74,74,1)"
                    data-sold-out-button-clickable="true"
                    
                    data-event-rsvp-position="hidden"
                    data-event-rsvp-cta-size="medium"
                    data-event-rsvp-only-show-icon="false"
                    data-event-rsvp-text="RSVP"
                    data-event-rsvp-icon="false"
                    data-event-rsvp-cta-text-color="rgba(74,74,74,1)"
                    data-event-rsvp-cta-bg-color="rgba(255,255,255,1)"
                    data-event-rsvp-cta-border-color="rgba(74,74,74,1)"
                    data-event-rsvp-cta-border-width="1px"
                    data-event-rsvp-cta-border-radius="2px"
                    
                    data-follow-section-position="hidden"
                    data-follow-section-alignment="center"
                    data-follow-section-header-text="Get updates on new shows, new music, and more"
                    data-follow-section-cta-size="medium"
                    data-follow-section-cta-text="FOLLOW"
                    data-follow-section-cta-icon="false"
                    data-follow-section-cta-text-color="rgba(255,255,255,1)"
                    data-follow-section-cta-bg-color="rgba(74,74,74,1)"
                    data-follow-section-cta-border-color="rgba(74,74,74,1)"
                    data-follow-section-cta-border-width="0px"
                    data-follow-section-cta-border-radius="2px"
                    
                    data-play-my-city-position="hidden"
                    data-play-my-city-alignment="center"
                    data-play-my-city-header-text="Don’t see a show near you?"
                    data-play-my-city-cta-size="medium"
                    data-play-my-city-cta-text="REQUEST A SHOW"
                    data-play-my-city-cta-icon="false"
                    data-play-my-city-cta-text-color="rgba(255,255,255,1)"
                    data-play-my-city-cta-bg-color="rgba(74,74,74,1)"
                    data-play-my-city-cta-border-color="rgba(74,74,74,1)"
                    data-play-my-city-cta-border-width="0px"
                    data-play-my-city-cta-border-radius="2px"
                    
                    data-optin-font=""
                    data-optin-text-color=""
                    data-optin-bg-color=""
                    data-optin-cta-text-color=""
                    data-optin-cta-bg-color=""
                    data-optin-cta-border-width=""
                    data-optin-cta-border-radius=""
                    data-optin-cta-border-color=""
                    
                    data-language="en"
                    data-layout-breakpoint="900"
                    data-app-id=""
                    data-affil-code=""
                    data-bit-logo-position="hidden"
                    data-bit-logo-color="rgba(0,0,0,1)"
                    
                    ></a>
            </div>
        </Popup>
    );
}
