import { faApple, faSpotify } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import useSpotifyService from "../../features/Spotify/useSpotifyService";
import {
  useAppleSelector,
  useSpotifySelector,
} from "../../features/useSelector";
import useSpotify from "../../helpers/spotify/useSpotify";
import useAppleMusicKit from "../../helpers/apple/useAppleMusicKit";
import useAppleService from "../../features/Apple/useAppleService";

export default function SavePlaylist() {
  // spotify
  const { user, playlist } = useSpotify();
  const { generatedPlaylist, spotifyLoggedIn, currentPopSetlistId } =
    useSpotifySelector();
  const { generatePlaylist, setCurrentPopSetlistId, setSpotifyLoggedIn } =
    useSpotifyService();
  // apple
  const appleMusicKit = useAppleMusicKit();
  const { setAppleAccessToken, setAppleLoggedIn } = useAppleService();
  const { appleAccessToken, appleLoggedIn } = useAppleSelector();

  useEffect(() => {
    const checkStorage = () => {
      const token = !!localStorage.getItem("spotify_access_token");
      setSpotifyLoggedIn(token);
    };

    window.addEventListener("storage", checkStorage);
  }, []);

  function loginToSotipy() {
    user.fire();
  }

  async function saveToSpotifyPlaylist() {
    const accessToken = user.credentials().access_token as string;

    console.log(accessToken);

    // check access token first
    if (!accessToken) {
      // opens a new window to spotify
      user.fire();

      alert("Please re-authenticate to Spotify.");
      return;
    }

    const userInfo = await user.getUserInfo(accessToken);

    //   create playlist
    if (userInfo?.status !== 200) return;
    const createdPlaylist = await playlist.create({
      user_id: userInfo.data.id,
      name: "SAINt JHN Coachella",
      description: "My favorite setlist of the year!",
      userAccessToken: accessToken,
    });

    // add all tracks to new playlist
    if (createdPlaylist!.status !== 201) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: createdPlaylist?.statusText,
      });
    }

    const uris: any = [];

    generatedPlaylist.forEach((track) => {
      uris.push(track.uri);
    });

    const addedTracks = await playlist.add({
      uris,
      playlist_id: createdPlaylist?.data.id,
      userAccessToken: accessToken,
    });

    if (addedTracks!.status === 201) {
      Swal.fire({ icon: "success", showConfirmButton: false, timer: 3000 });

      // signout
      user.unauthorize();
      setSpotifyLoggedIn(false);
    }
  }

  async function saveToApplePlaylist() {
    const userToken = await appleMusicKit.kit.authorize();

    if (!userToken) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Your account is not on premium",
      });
      await appleMusicKit.kit.unauthorize();
    }

    // set the token in redux
    setAppleAccessToken(userToken);
    setAppleLoggedIn(true);

    // create the playlist
    // add all tracks to playlist
    const createdPlaylist = await appleMusicKit.createPlaylist(
      generatedPlaylist,
      userToken
    );

    if (!createdPlaylist.ok) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: createdPlaylist.statusText,
      });
    } else {
      Swal.fire({ icon: "success", showConfirmButton: false, timer: 3000 });

      // signout
      await appleMusicKit.kit.unauthorize();
      setAppleAccessToken("");
      setAppleLoggedIn(false);
    }
  }

  return (
    <div className={`${currentPopSetlistId === 2 ? "block" : "hidden"}`}>
      <div
        className={`${currentPopSetlistId === 2 ? "opacity-100 top-0" : "opacity-0 top-[-10%]"} transition-all duration-700 mt-[2vw] gap-[2vw] md:gap-[1vw] flex flex-col justify-center w-full items-center`}
      >
        <div className="w-full">
          <h2 className="section-heading text-center">
            My Dream SAINt JHN Coachella Setlist
          </h2>
          <div
            role="group"
            className="text-left border border-white p-[2vw] mt-[1vw] h-[40vh] overflow-y-scroll"
          >
            {generatedPlaylist.map((e: any, idx) => (
              <label
                key={idx}
                className="input-label text-left whitespace-nowrap"
              >
                <span className="ml-[1vw] inline-block mt-[1vw]">{e.name}</span>
              </label>
            ))}
          </div>
        </div>
        <div className="w-full text-center flex flex-col gap-[--space-between-items]">
          {spotifyLoggedIn ? (
            <button
              className="w-full btn-primary bg-green-600 border-green-600 hover:text-green-600"
              onClick={() => {
                saveToSpotifyPlaylist();
              }}
            >
              save playlist{" "}
              <FontAwesomeIcon icon={faSpotify} className="ml-[.5vw]" />
            </button>
          ) : (
            <button
              className="w-full btn-primary"
              onClick={() => {
                loginToSotipy();
              }}
            >
              LOGIN SPOTIFY{" "}
              <FontAwesomeIcon icon={faSpotify} className="ml-[.5vw]" />
            </button>
          )}

          {appleLoggedIn ? (
            <a
              referrerPolicy="origin"
              onClick={() => {
                saveToApplePlaylist();
              }}
              className="w-full btn-primary cursor-pointer ml-[.5vw]"
            >
              save playlist <FontAwesomeIcon icon={faApple} />
            </a>
          ) : (
            <a
              referrerPolicy="origin"
              onClick={() => {
                saveToApplePlaylist();
              }}
              className="w-full btn-primary cursor-pointer"
            >
              Authorize Apple
              <FontAwesomeIcon icon={faApple} className="ml-[.5vw]" />
            </a>
          )}

          <button
            className="mt-[1vw] btn-link text-center"
            onClick={() => {
              generatePlaylist([]); // setback to empty
              setCurrentPopSetlistId(1); // change page
            }}
          >
            Try Again
          </button>
        </div>
      </div>
    </div>
  );
}
