import handlePlaylist from "./handlePlaylist";
import spotifyAuth from "./spotifyAuth";
import spotifyTracks from "./spotifyTracks";
import spotifyUserLogin from "./spotifyOAuth";

export default function useSpotify() {
  // gets the spotify token
  function auth() {
    return spotifyAuth();
  }

  // get the artist's top tracks
  function tracks() {
    return spotifyTracks();
  }

  // authenticate other user
  function user() {
    return spotifyUserLogin();
  }

  // handle playlists
  function playlist() {
    return handlePlaylist();
  }

  return {
    auth: auth(),
    tracks: tracks(),
    user: user(),
    playlist: playlist(),
  };
}
