interface SingleFileUploaderProps {
  set: (value: File) => void;
  file: File | null;
  type: string;
}

export default function SingleFileUploader({
  set,
  file,
  type,
}: SingleFileUploaderProps) {
  return (
    <div className="relative flex flex-row justify-center items-center gap-[--space-between-items]">
      {/* invisble input */}
      <input
        className="hidden-file-input absolute top-0 left-0 w-full h-full opacity-0"
        type="file"
        name="file"
        accept={type === "photo" ? ".png,.jpg,.webp" : "video/*"}
        onChange={(e) => {
          const files = e.currentTarget.files;
          if (!files) return;

          set(files[0]);
        }}
      />

      <button className="btn-secondary overflow-ellipsis overflow-hidden whitespace-nowrap">
        {file && file.name ? file.name : "Choose file"}
      </button>
      <span className=" overflow-ellipsis overflow-hidden whitespace-nowrap">
        Upload a {type}
      </span>
    </div>
  );
}
