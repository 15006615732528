import { Dispatch } from "redux";
import { setCurrentRoute, setIsIOS, setIsRouteChanged } from "./Routes.slice";

import Store from "../../Store";

export default class RoutesService {
  dispatch: Dispatch;

  constructor() {
    this.dispatch = Store.dispatch;
  }

  setIsRouteChanged(value: boolean) {
    Store.dispatch(setIsRouteChanged(value));
  }

  setCurrentRoute(value: string) {
    Store.dispatch(setCurrentRoute(value));
  }

  setIsIOS(payload: boolean) {
    Store.dispatch(setIsIOS(payload));
  }
}
