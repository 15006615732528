import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useSpotifyService from "../features/Spotify/useSpotifyService";
import { motion } from "framer-motion";
import { fadeInOut } from "../util";
import useGlobalService from "../features/Global/useGlobalService";

interface PopupProps {
  className?: string;
  children?: any;
  open: boolean;
  setOpen: (value: boolean) => void;
}

const Popup = ({ open, setOpen, className, children }: PopupProps) => {
  const { setCurrentPopSetlistId } = useSpotifyService();
  const { setSubmitType } = useGlobalService();

  return (
    <div
      className={`flex left-0 fixed w-full h-screen px-[--default-space] ${
        open ? "top-0" : "-top-[120%] delay-300"
      }`}
      style={{ zIndex: 123123123123 }}
    >
      <motion.div
        initial="hidden"
        animate={open ? "visible" : "hidden"}
        variants={fadeInOut}
        className={`relative bg-black m-auto shadow-lg w-full md:w-auto ${className}`}
      >
        <button
          className="absolute -top-5 -right-5 text-primary rounded-full z-20"
          onClick={() => {
            setOpen(false);

            // back to 0
            setCurrentPopSetlistId(0);
            // erase submit type
            setSubmitType("");
          }}
        >
          <FontAwesomeIcon icon={faXmarkCircle} fontSize="1.5rem" />
        </button>
        {children}
      </motion.div>
    </div>
  );
};

export default Popup;
