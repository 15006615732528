import NotACultPopup from "../../components/NotACultPopup";
import OverflowVideo from "../../components/OverflowVideo";
import PresavePopup from "../../components/PresavePopup";
import Setlist from "../../components/Setlist/Setlist";
import ShareExperience from "../../components/ShareExperience/ShareExperience";
import ShowOption from "../../components/ShareExperience/ShowOption";
import UnreleasedTrackPopup from "../../components/UnreleasedTrackPopup";
import Tour from "../../components/Tour/Tour";

const Home = () => {
  return (
    <div
      id="home"
      className="relative h-screen flex flex-col justify-center items-center"
    >
      {/* Required users to enter site */}
      <PresavePopup />

      {/* BG VIDEO */}
      <OverflowVideo />

      {/* // ---# SPEAKER */}
      <Setlist />

      {/* // ---# CHILD */}
      <ShareExperience />

      {/* // ---# NOT A CULT */}
      <ShowOption />
      <NotACultPopup />

      {/* // ---# SUPERMAN */}
      <UnreleasedTrackPopup />

      <Tour />
    </div>
  );
};

export default Home;
