import { useState } from "react";
import useSpotifyService from "../../features/Spotify/useSpotifyService";
import { useSpotifySelector } from "../../features/useSelector";
import Popup from "../Popup";
import GrabEmail from "./GrabEmailForm";
import SavePlaylist from "./SavePlaylist";
import SelectTracks from "./SelectTracks";
import GrabEmailForm from "./GrabEmailForm";

export default function Setlist() {
  const { setPopupSetlist } = useSpotifyService();
  const { popSetlist, currentPopSetlistId } = useSpotifySelector();
  const [email, setEmail] = useState("");

  return (
    <Popup
      open={popSetlist}
      setOpen={setPopupSetlist}
      className={`p-[--default-space] ${currentPopSetlistId === 0 ? "lg:w-[60%]" : ""}`}
    >
      <GrabEmailForm setEmail={setEmail} />
      <SavePlaylist />
      <SelectTracks />
    </Popup>
  );
}
