import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { googleSheetUrl_tourexperience } from "../../constants";
import { Error, Success } from "../../helpers/google/handleGoogleSheet";
import useGoogleSheet from "../../helpers/google/useGoogleSheet";
import InputField from "../Form/InputField";
import SingleFileUploader from "./SingleFileUploader";
import { useGlobalSelector } from "../../features/useSelector";

import useGlobalService from "../../features/Global/useGlobalService";

export default function TourExperienceForm() {
  const [file, setFile] = useState<File | null>(null);
  const { googleSheet } = useGoogleSheet();
  const { submitType } = useGlobalSelector();
  const { setSubmitType } = useGlobalService();

  return (
    <div className="z-10 relative p-[--default-space]">
      {/* // ---# Form */}
      <h2 className="section-heading text-center">Share your tour memories</h2>
      <p className="mt-[--space-between-items] text-center">
        Upload Your Memories of SAINt JHN
      </p>
      <p className="label-small text-center">
        <span className="font-serif">*</span>this can repurposed or used for any
        future content
      </p>
      <Formik
        initialValues={{ email: "", name: "", place: "", message: "" }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);

          // uploading the file
          let file_url;
          if (file) {
            const { files } = await googleSheet.uploadFiles(file);
            file_url = files[0].file_url;
          }

          // uploading the form
          const res = await googleSheet.uploadForm(
            { ...values, file_url },
            googleSheetUrl_tourexperience
          );

          if (res.result === "success") {
            Success();
            resetForm();
            setSubmitType("");
            setFile(null);
          } else {
            Error();
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting, handleChange, initialValues }) => (
          <Form className="mt-[--space-between-sections] gap-[--space-between-items] flex flex-col w-full items-center">
            <div className="gap-[--space-between-items] flex flex-col md:flex-row w-full">
              <InputField
                name="name"
                type="text"
                placeholder="Your Name"
                required
              />
              <InputField
                name="email"
                type="email"
                placeholder="Enter email address"
                required
              />
            </div>
            <div className="w-full">
              <InputField
                name="place"
                type="text"
                placeholder="What venue and time?"
                required
              />
            </div>
            <div className="w-full field-container">
              <textarea
                onChange={handleChange}
                rows={5}
                className="field-input"
                name="message"
                placeholder="Write your memories here"
              />
            </div>
            <div className="w-full">
              <SingleFileUploader type={submitType} file={file} set={setFile} />
            </div>

            <div className="w-full">
              <button className="w-full btn-primary text-[100%]" type="submit">
                {isSubmitting ? (
                  <span>
                    Submitting{" "}
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="animate-bounce"
                    />
                  </span>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
