import { dispatch } from "../../Store";
import { setAppleAccessToken, setAppleLoggedIn } from "./Apple.slice";

export default class AppleService {
  setAppleAccessToken(payload: string) {
    dispatch(setAppleAccessToken(payload));
  }
  setAppleLoggedIn(payload: boolean) {
    dispatch(setAppleLoggedIn(payload));
  }
}
