import axios from "axios";

export type CreatePlaylist = {
  user_id: string;
  userAccessToken: string;
  name: string;
  description: string;
};

export type AddToPlaylist = {
  playlist_id: string;
  userAccessToken: string;
  uris: [];
};

export default function handlePlaylist() {
  async function create({
    user_id,
    userAccessToken,
    name,
    description,
  }: CreatePlaylist) {
    try {
      const body = JSON.stringify({
        name,
        description,
      });

      console.log("CURRENT USER_ID:", user_id);
      const response = await axios.post(
        `https://api.spotify.com/v1/users/${user_id}/playlists`,
        body,
        {
          headers: {
            Authorization: `Bearer ${userAccessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response;
      // return response;
    } catch (error) {
      console.log(error);
    }
  }

  async function add({ userAccessToken, playlist_id, uris }: AddToPlaylist) {
    try {
      const body = JSON.stringify({
        uris,
        position: 0,
      });

      const response = await axios.post(
        `https://api.spotify.com/v1/playlists/${playlist_id}/tracks`,
        body,
        {
          headers: {
            Authorization: `Bearer ${userAccessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response;
    } catch (error) {
      console.log(error);
    }
  }

  return {
    create,
    add,
  };
}
