import axios from "axios";
import qs from "qs";
import { token_url } from "../../constants";

export interface SpotifyAuth {
  client_id: string;
  client_secret: string;
}

export default function spotifyAuth() {
  async function oauth({ client_id, client_secret }: SpotifyAuth) {
    try {
      //make post request to SPOTIFY API for access token, sending relavent info
      const data = qs.stringify({
        grant_type: "client_credentials",
        client_id,
        client_secret,
      });

      const response = await axios.post(token_url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      //return access token
      return response.data.access_token;
    } catch (error) {
      //on fail, log the error in console
      console.log(error);
    }
  }

  return {
    oauth,
  };
}
