import axios from "axios";
import qs from "qs";
import {
  authorize_url,
  client_id,
  client_secret,
  redirect_uri,
  token_url,
} from "../../constants";

export default function spotifyOAuth() {
  async function fire() {
    // requestAuthorization();
    const scope =
      "user-read-private user-read-email user-library-read streaming playlist-read-private playlist-modify-public playlist-modify-private user-library-modify";
    window.open(
      `${authorize_url}?response_type=code&client_id=${client_id}&scope=${encodeURIComponent(scope)}&redirect_uri=${redirect_uri}&state=atz8TeYIGzwOfl5o&show_dialog=true`,
      "SpotifyWindow",
      "width=600,height=500"
    );
  }

  // spotify route will call this
  async function login() {
    return await getCodeParam();
  }

  async function getCodeParam() {
    let code = null;

    const queryString = window.location.search;
    if (queryString.length > 0) {
      const urlParams = new URLSearchParams(queryString);
      code = urlParams.get("code");
    }

    return await fetchAccessToken(code as string);
  }

  async function fetchAccessToken(code: string) {
    const data = qs.stringify({
      grant_type: "authorization_code",
      client_id,
      client_secret,
      redirect_uri,
      code,
    });
    try {
      const response = await axios.post(token_url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Basic " + btoa(client_id + ":" + client_secret),
        },
      });

      const { access_token, refresh_token } = response.data;

      localStorage.setItem("spotify_access_token", access_token);
      localStorage.setItem("spotify_refresh_token", refresh_token);

      window.close();

      return response;
    } catch (error) {
      console.log(error);
    }
  }

  function userTokens() {
    return {
      access_token: localStorage.getItem("spotify_access_token"),
      refresh_token: localStorage.getItem("spotify_refresh_token"),
    };
  }

  function unauthorize() {
    localStorage.setItem("spotify_access_token", "");
    localStorage.setItem("spotify_refresh_token", "");
  }

  async function getUserInfo(accessToken: string) {
    // console.log(accessToken);
    try {
      const response = await axios.get("https://api.spotify.com/v1/me", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error) {
      console.log(error);
    }
  }

  return {
    fire,
    login,
    getCodeParam,
    fetchAccessToken,
    credentials: userTokens,
    getUserInfo,
    unauthorize,
  };
}
