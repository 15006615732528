// import BG_IMG from "../assets/images/Landing Page Home.png";
// import BG_VIDEO from "../assets/videos/bg.desktop.mp4";
import { useEffect, useRef } from "react";
import useGlobalService from "../features/Global/useGlobalService";
import { useGlobalSelector } from "../features/useSelector";
import ClickableButtons from "./ClickableButtons";
import Indicator from "./Indicator";
import $ from "jquery";
import MyAsset from "../asset_strings";

export default function OverflowVideo() {
  const { setShowDragLeftRightIndicator } = useGlobalService();
  const { presavePopupOpen } = useGlobalSelector();

  function handleTouch() {
    setTimeout(() => {
      setShowDragLeftRightIndicator(false);
    }, 2000);
  }

  return (
    <div
      onTouchStart={handleTouch}
      onTouchEnd={handleTouch}
      onTouchMove={handleTouch}
      className="w-full lg:w-full h-screen  lg:overflow-hidden"
    >
      <div
        id="video"
        className={`${presavePopupOpen ? "blur-[10px]" : "blue-0"} transition-all w-[1400px] h-full relative bg-white text-white lg:w-full`}
      >
        <video
          className="absolute top-0 w-full object-top h-auto left-0 lg:h-full lg:object-cover"
          playsInline
          autoPlay
          loop
          muted
          src={MyAsset.bgVideo}
          poster={MyAsset.bgPoster}
        ></video>
        <ClickableButtons />
        <Indicator />
      </div>
    </div>
  );
}
