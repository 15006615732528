import useGlobalService from "../../features/Global/useGlobalService";
import { useGlobalSelector } from "../../features/useSelector";
import Popup from "../Popup";
import TourExperienceForm from "./TourExperienceForm";

export default function ShareExperience() {
  const { setPopShareTourExperience } = useGlobalService();
  const { popShareTourExperience } = useGlobalSelector();

  return (
    <Popup open={popShareTourExperience} setOpen={setPopShareTourExperience}>
      <TourExperienceForm />
    </Popup>
  );
}
