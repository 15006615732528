import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGlobalSelector } from "../features/useSelector";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

export default function Indicator() {
  const { showDragLeftRightIndicator } = useGlobalSelector();

  return (
    <div
      id="indicator"
      className={`fixed text-black bottom-[10%] left-1/2 transform -translate-x-1/2 z-[100]`}
    >
      <div className="flex flex-col justify-center items-center">
        <div
          className={`flex-row items-center gap-[--space-between-items] flashing ${!showDragLeftRightIndicator ? "hidden" : "flex"}`}
        >
          <FontAwesomeIcon
            className="text-lg md:text-[2vw]"
            icon={faArrowLeft}
          />
          <span className="shadow-md btn-primary rounded-full font-bold">
            DRAG LEFT/RIGHT
          </span>
          <FontAwesomeIcon
            className="text-lg md:text-[2vw]"
            icon={faArrowRight}
          />
        </div>
        <span className="font-bold btn-primary rounded-full whitespace-nowrap mt-[--space-between-items] text-black">
          Orange Circles Are Clickable
        </span>
      </div>
    </div>
  );
}
