const MyAsset = {
  photoOptionImg:
    "https://raw.githubusercontent.com/kevin700brands/saint-jhn-festival-assets/refs/heads/main/images/photo-option.png",
  videoOptionGIF:
    "https://raw.githubusercontent.com/kevin700brands/saint-jhn-festival-assets/refs/heads/main/videos/video%20option.gif",
  videoOptionMP4:
    "https://raw.githubusercontent.com/kevin700brands/saint-jhn-festival-assets/refs/heads/main/videos/video%20option.mp4",
  albumArt:
    "https://raw.githubusercontent.com/kevin700brands/saint-jhn-festival-assets/refs/heads/main/images/feature.png",
  bgVideo:
    "https://raw.githubusercontent.com/kevin700brands/saint-jhn-festival-assets/refs/heads/main/videos/bg-2.mp4",
  bgPoster:
    "https://raw.githubusercontent.com/kevin700brands/saint-jhn-festival-assets/refs/heads/main/images/bg-2.png",
  unreleasedClip:
    "https://raw.githubusercontent.com/kevin700brands/saint-jhn-festival-assets/refs/heads/main/audio/clip-2.mp3",
};

export default MyAsset;
