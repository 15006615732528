export const YTEmbed = "https://www.youtube-nocookie.com/embed/";
export const isMobile = window.innerWidth <= 768;
export const TRANSITION_DURATION = 2000;
export const EASING = [0.4, 0, 1, 1];

// spotify api auth
// export const client_id = "5547cb8c37cb448b96e48ed920501070";
// export const client_secret = "e44130db665d468d98806525f0b1b07b";
export const client_id = "1c6bc79302f446279a92c917b127bd09";
export const client_secret = "e1f9435d382844a3b4c6a2232649eb25";
export const artistId = "0H39MdGGX6dbnnQPt6NQkZ";
export const playlistId = "3RBH4dAJm7DbWma23P6VFy";
export const redirect_uri =
  // "http://localhost:3000/spotify";
  "http://saintjhn-festival-season.700sandbox.com/spotify";
export const token_url = "https://accounts.spotify.com/api/token";
export const authorize_url = "https://accounts.spotify.com/authorize";

// google file uploads
export const folderUrl =
  "https://drive.google.com/drive/u/1/folders/1Wh45rV4j3TyJ6ycxOltr57Wtsd7cAuCL";
export const upload_file_url = "https://g-api.700sandbox.com/upload.php";
export const folderId = "1Wh45rV4j3TyJ6ycxOltr57Wtsd7cAuCL";
export const googleSheetUrl_tourexperience =
  "https://script.google.com/macros/s/AKfycbzR-qCcFzql-Sn8hQi2aLsRYtQbI69uZdCcur2l9sIbwo6GTvSn_QN-AgiKM6S3VNE-zw/exec";
export const googleSheetUrl_notacult =
  "https://script.google.com/macros/s/AKfycbypAXTMJSm0SwVEMMqAJKTj6Gfz0qFEpx4vJF2_sVaLP6Rs_MkKfjqh7C2nA5wC1bQ-/exec";
export const googleSheetUrl_unreleasedtracks =
  "https://script.google.com/macros/s/AKfycbygeCY0qjS4SQTuQRNdzUyvuzrNaO9g0OyMt-xPImlUcuC_ICqHAMuiBhD2WxnyZAoA/exec";

// apple muisc api
export const apple_search_url = "v1/catalog/us/search?term=";
export const apple_create_playlist =
  "https://api.music.apple.com/v1/me/library/playlists";
export const apple_dev_token =
  "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjQ4NTZKNkdESEcifQ.eyJpYXQiOjE3MzU5MzYzMDgsImV4cCI6MTczODUyODMwOCwiaXNzIjoiOVZOQVU3Rjc5SiJ9.ob4DGwbiMVX2EXfX6mORJKSM_G6Si7rGzZJjwK8LF2LhZXP7u7bQzrUz7NyTuxsd2N1BEO9PwRlm6rifGrmWqQ";
