import styled from "styled-components";
import { useRef, useState } from "react";

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

interface BackgroundVideoProps {
  VIDEOMP4: string;
  VIDEOMP4MOBILE?: string;
  VIDEOGIF: string;
}

export default function BackgroundVideo({
  VIDEOMP4,
  VIDEOGIF,
  VIDEOMP4MOBILE,
}: BackgroundVideoProps) {
  const isMobile = window.innerWidth <= 768;
  const videoRef: any = useRef(null);
  const [isPaused, setIsPaused] = useState(false);

  // check if paused
  function handleTimeUpdate(e: React.SyntheticEvent<HTMLVideoElement, Event>) {
    const target = e.currentTarget;

    if (target.paused) {
      setIsPaused(target.paused);
    }
  }

  return (
    <div className="w-full h-full z-0">
      {isPaused ? (
        <img
          src={VIDEOGIF}
          alt="video"
          className="w-full h-full object-cover relative z-20"
        />
      ) : (
        <video
          ref={videoRef}
          preload="metadata"
          playsInline
          autoPlay
          loop
          muted
          onTimeUpdate={handleTimeUpdate}
          className="w-full h-full object-cover relative z-20"
        >
          <source
            src={
              !isMobile ? VIDEOMP4 : VIDEOMP4MOBILE ? VIDEOMP4MOBILE : VIDEOMP4
            }
            type="video/mp4"
          />
        </video>
      )}
    </div>
  );
}
