import { Form, Formik } from "formik";
import useSpotifyService from "../../features/Spotify/useSpotifyService";
import { useSpotifySelector } from "../../features/useSelector";
import InputField from "../Form/InputField";
import MyAsset from "../../asset_strings";

interface GrabEmailFormProps {
  setEmail: (email: string) => void;
}

export default function GrabEmailForm({ setEmail }: GrabEmailFormProps) {
  const { setCurrentPopSetlistId } = useSpotifyService();
  const { currentPopSetlistId } = useSpotifySelector();

  return (
    <div className={`${currentPopSetlistId === 0 ? "block" : "hidden"}`}>
      <div className="flex flex-col lg:flex-row items-center gap-[--space-between-items]">
        <div className="w-full">
          <img className="w-full object-cover" src={MyAsset.albumArt} alt="" />
        </div>

        <div>
          <h2 className="section-heading text-center">
            Help Me Create My Coachella Setlist
          </h2>
          <Formik
            initialValues={{ email: "" }}
            onSubmit={(values) => {
              setEmail(values.email);
              //   change page
              setCurrentPopSetlistId(1);
            }}
          >
            {() => (
              <Form className="mt-[2vw] gap-[2vw] md:gap-[1vw] flex flex-col w-full items-center">
                <InputField
                  name="email"
                  type="email"
                  placeholder="Enter email address"
                />
                <div className="w-full">
                  <button
                    className="w-full btn-primary text-[100%]"
                    type="submit"
                  >
                    Create Playlist
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
