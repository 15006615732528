import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type InitialGlobalState = {
  isBurgerActive: boolean;
  isLoaded: boolean;
  isDropdownActive: boolean;
  dropdownId: number | null;
  isMenuActive: boolean;
  popShareTourExperience: boolean;
  popUnreleasedTrack: boolean;
  popNotACult: boolean;
  showDragLeftRightIndicator: boolean;
  presavePopupOpen: boolean;
  submitType: string;
  showOption: boolean;
  showTour: boolean;
};

const initialState: InitialGlobalState = {
  isBurgerActive: false,
  isLoaded: false,
  isDropdownActive: false,
  dropdownId: -1,
  isMenuActive: false,
  popShareTourExperience: false,
  popUnreleasedTrack: false,
  popNotACult: false,
  showDragLeftRightIndicator: true,
  presavePopupOpen: true,
  submitType: "",
  showOption: false,
  showTour: false,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setIsBurgerActive: (state, { payload }: PayloadAction<boolean>) => {
      state.isBurgerActive = payload;
    },
    setVideoPopupState: (state, { payload }) => {
      state.isLoaded = payload;
    },
    setIsLoaded: (state, { payload }) => {
      state.isLoaded = payload;
    },
    setIsMenuActive: (state, { payload }: PayloadAction<boolean>) => {
      state.isMenuActive = payload;
    },
    setIsDropdownActive: (state, { payload }) => {
      state.isDropdownActive = payload;
    },
    setDropdownId: (state, { payload }) => {
      state.dropdownId = payload;
    },
    setPopShareTourExperience: (state, { payload }) => {
      state.popShareTourExperience = payload;
    },
    setPopNotACult: (state, { payload }) => {
      state.popNotACult = payload;
    },
    setPopUnreleasedTrack: (state, { payload }) => {
      state.popUnreleasedTrack = payload;
    },
    setShowDragLeftRightIndicator: (state, { payload }) => {
      state.showDragLeftRightIndicator = payload;
    },
    setPresavePopupOpen: (state, { payload }) => {
      state.presavePopupOpen = payload;
    },
    setSubmitType: (state, { payload }) => {
      state.submitType = payload;
    },
    setShowOption: (state, { payload }) => {
      state.showOption = payload;
    },
    setShowTour: (state, { payload }) => {
      state.showTour = payload;
    },
  },
});

export const {
  setIsBurgerActive,
  setIsLoaded,
  setDropdownId,
  setIsDropdownActive,
  setIsMenuActive,
  setPopShareTourExperience,
  setPopNotACult,
  setPopUnreleasedTrack,
  setShowDragLeftRightIndicator,
  setPresavePopupOpen,
  setSubmitType,
  setShowOption,
  setShowTour,
} = globalSlice.actions;
