import MyAsset from "../../asset_strings";
import useGlobalService from "../../features/Global/useGlobalService";
import { useGlobalSelector } from "../../features/useSelector";
import BackgroundVideo from "../BackgroundVideo/BackgroundVideo";
import Popup from "../Popup";

export default function ShowOption() {
  const { setSubmitType, setShowOption, setPopShareTourExperience } =
    useGlobalService();
  const { showOption } = useGlobalSelector();

  return (
    <Popup open={showOption} setOpen={setShowOption} className="lg:w-3/4">
      <h2 className="section-heading text-center py-[--space-between-items]">
        Share Your Memories With Me
      </h2>
      <div className={`flex gap-[--space-between-items] flex-row`}>
        <div className="flex flex-col">
          <img
            className="w-full h-full object-cover"
            src={MyAsset.photoOptionImg}
            alt="submit"
          />
          <button
            className="btn-primary"
            onClick={() => {
              setSubmitType("photo");
              setShowOption(false);
              setPopShareTourExperience(true);
            }}
          >
            Submit a photo
          </button>
        </div>

        <div className="flex flex-col">
          <BackgroundVideo
            VIDEOGIF={MyAsset.videoOptionGIF}
            VIDEOMP4={MyAsset.videoOptionMP4}
            VIDEOMP4MOBILE={MyAsset.videoOptionMP4}
          />
          <button
            className="btn-primary"
            onClick={() => {
              setSubmitType("video");
              setShowOption(false);
              setPopShareTourExperience(true);
            }}
          >
            Submit a video
          </button>
        </div>
      </div>
    </Popup>
  );
}
