import axios from "axios";
import { access } from "fs";
import qs from "qs";

export interface SpotifyTopTracks {
  accessToken: string;
  artistId: string;
}

export interface SpotifyPlaylist {
  accessToken: string;
  playlistId: string;
}

export default function spotifyTracks() {
  async function getPlaylist({ accessToken, playlistId }: SpotifyPlaylist) {
    try {
      const url = `https://api.spotify.com/v1/playlists/${playlistId}`;

      const response = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async function getTopTracks({ accessToken, artistId }: SpotifyTopTracks) {
    try {
      const url = `https://api.spotify.com/v1/artists/${artistId}/top-tracks`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async function saveTracks(ids: [], accessToken: string) {
    try {
      // ids=4iV5W9uYEdYUVa79Axb7Rh,1301WleyT98MSxVHPZCA6M.

      const data = qs.stringify({
        ids,
      });

      const response = await axios.put(
        `https://api.spotify.com/v1/me/tracks?ids=${encodeURIComponent(ids.toLocaleString())}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response);

      return response;
    } catch (error) {
      console.log(error);
    }
  }

  return {
    getTopTracks,
    saveTracks,
    getPlaylist,
  };
}
