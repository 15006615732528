import { createSlice } from "@reduxjs/toolkit";

export type InitialSpotifyState = {
  popSetlist: boolean;
  currentPopSetlistId: number;
  generatedPlaylist: any[];
  spotifyAccessToken: string;
  spotifyLoggedIn: boolean;
};

const initialState: InitialSpotifyState = {
  popSetlist: false,
  currentPopSetlistId: 0,
  generatedPlaylist: [],
  spotifyAccessToken: "",
  spotifyLoggedIn: false,
};

export const spotifySlice = createSlice({
  name: "spotify",
  initialState: initialState,
  reducers: {
    setPopsetlist: (state, { payload }) => {
      state.popSetlist = payload;
    },
    setCurrentPopSetlistId: (state, { payload }) => {
      state.currentPopSetlistId = payload;
    },
    generatePlaylist: (state, { payload }) => {
      state.generatedPlaylist = payload;
    },
    setSpotifyAccessToken: (state, { payload }) => {
      state.spotifyAccessToken = payload;
    },
    setSpotifyLoggedIn: (state, { payload }) => {
      state.spotifyLoggedIn = payload;
    },
  },
});

export const {
  setPopsetlist,
  generatePlaylist,
  setCurrentPopSetlistId,
  setSpotifyAccessToken,
  setSpotifyLoggedIn,
} = spotifySlice.actions;
