import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, Form } from "formik";
import { Success } from "../helpers/google/handleGoogleSheet";
import useGoogleSheet from "../helpers/google/useGoogleSheet";
import InputField from "./Form/InputField";
import { useGlobalSelector } from "../features/useSelector";
import useGlobalService from "../features/Global/useGlobalService";
import Popup from "./Popup";
import { googleSheetUrl_notacult } from "../constants";

export default function NotACultPopup() {
  const { setPopNotACult } = useGlobalService();
  const { popNotACult } = useGlobalSelector();
  const { googleSheet } = useGoogleSheet();

  return (
    <Popup
      open={popNotACult}
      setOpen={setPopNotACult}
      className="lg:w-1/2 p-[--default-space] "
    >
      <div>
        <h2 className="section-heading text-center">
          I want to take ‘Not a Cult’ with me to Coachella
        </h2>

        <Formik
          initialValues={{ my_link: "", phone_number2: "", contact_email2: "" }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);

            // uploading the form
            const res = await googleSheet.uploadForm(
              { ...values },
              googleSheetUrl_notacult
            );

            if (res.result === "success") {
              Success();
              resetForm();
            } else {
              Error();
            }

            setSubmitting(false);
          }}
        >
          {({ isSubmitting, handleChange, initialValues }) => (
            <Form className="mt-[--space-between-sections] gap-[--space-between-items] flex flex-col w-auto items-center">
              <InputField
                name="my_link"
                type="text"
                placeholder="Paste Link Here"
                required
              />
              <InputField
                name="phone_number2"
                type="text"
                placeholder="Enter phone number"
                required
              />
              <InputField
                name="contact_email2"
                type="email"
                placeholder="Enter email"
                required
              />

              <p className="text-center block w-full text-[100%] leading-[100%]">
                Submit a link of your tiktok/instagram post showing off your
                outfit with 'festival season' audio & use #festivalszn for a
                chance to attend coachella and see SAINt JHN perform
              </p>

              <div className="w-full">
                <button
                  className="w-full btn-primary text-[100%]"
                  type="submit"
                >
                  {isSubmitting ? (
                    <span>
                      Submitting{" "}
                      <FontAwesomeIcon
                        icon={faCircle}
                        className="animate-bounce"
                      />
                    </span>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Popup>
  );
}
