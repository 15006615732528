import { faApple, faSpotify } from "@fortawesome/free-brands-svg-icons";
import { faX, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import FEATURE_IMG from "../assets/images/feature.png";
import useGlobalService from "../features/Global/useGlobalService";
import { useGlobalSelector } from "../features/useSelector";
import { motion } from "framer-motion";
import { fade, fadeInOut } from "../util";
import { useEffect, useState } from "react";
import MyAsset from "../asset_strings";
import $ from "jquery";

export default function PresavePopup() {
  const { presavePopupOpen: open } = useGlobalSelector();
  const [initialOpen, setInitialOpen] = useState(false);
  const { setPresavePopupOpen } = useGlobalService();

  useEffect(() => {
    setTimeout(() => {
      setInitialOpen(open);
    }, 700);
  }, [open]);

  return (
    <div
      className={`font-secondary flex left-0 fixed w-full h-screen px-[--default-space] ${
        initialOpen ? "top-0 opacity-100" : "-top-[120%] opacity-0"
      }`}
      style={{ zIndex: 123123123123 }}
    >
      <motion.div
        initial="visible"
        animate={open ? "visible" : "hidden"}
        variants={fade}
        className={`bg-black border-[.2vw] border-brand relative overflow-hidden m-auto shadow-lg gap-[--space-between-items]  p-[2vw] flex flex-col justify-center items-center w-3/4 md:w-[35%]`}
      >
        <div className="absolute top-0 md:top-2 right-1 md:right-3 text-xl md:text-3xl cursor-pointer font-wingdings2" onClick={() => setPresavePopupOpen(false)}>Ò</div>
        <div>
          <h1 className="section-heading text-center text-brand px-8 md:px-0">
            Welcome to Festival Season
          </h1>
        </div>
        <div>
          <img className="w-full object-contain" src={MyAsset.albumArt} />
        </div>
        {/* <span>
          Pre<span className="font-serif">-</span>Save to Enter
        </span> */}
        <div className="flex flex-col md:flex-row w-full justify-between gap-[--space-between-items] text-[15px] lg:text-[1.7vw]">
          <button
            className="btn-primary text-[90%] md:text-[65%] z-10 w-full whitespace-nowrap"
            onClick={() => {
              setPresavePopupOpen(false);
              window.open("https://saintjhn.lnk.to/festivalseason", "_blank");

              // scroll at the middle
              const bgWidth = document.querySelector("#video")?.clientWidth;
              if (!bgWidth) return;
              document.body.scrollLeft = bgWidth / 2 - 200;
            }}
          >
            {/* Pre-Save */}
            Out Now
          </button>
          {/* <button
            className="btn-primary text-[90%] md:text-[45%] z-10 w-full whitespace-nowrap"
            onClick={() => {
              setPresavePopupOpen(false);

              document.body.scrollLeft = 500;
            }}
          >
            <FontAwesomeIcon icon={faSpotify} /> Connect on Spotify
          </button>
          <button
            className="btn-primary text-[90%] md:text-[45%] z-10 w-full whitespace-nowrap"
            onClick={() => {
              setPresavePopupOpen(false);
            }}
          >
            <FontAwesomeIcon icon={faApple} /> Connect on Apple
          </button> */}
        </div>
      </motion.div>
    </div>
  );
}
