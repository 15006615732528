import handleGoogleSheet from "./handleGoogleSheet";

export default function useGoogleSheet() {
  function googleSheet() {
    return handleGoogleSheet();
  }

  return {
    googleSheet: googleSheet(),
  };
}
