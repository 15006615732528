import { createSlice } from "@reduxjs/toolkit";

export type InitialAppleState = {
  appleAccessToken: string;
  appleLoggedIn: boolean;
};

const initialState: InitialAppleState = {
  appleAccessToken: "",
  appleLoggedIn: false,
};

export const appleSlice = createSlice({
  name: "spotify",
  initialState: initialState,
  reducers: {
    setAppleAccessToken: (state, { payload }) => {
      state.appleAccessToken = payload;
    },
    setAppleLoggedIn: (state, { payload }) => {
      state.appleLoggedIn = payload;
    },
  },
});

export const { setAppleAccessToken, setAppleLoggedIn } = appleSlice.actions;
