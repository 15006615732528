import { AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Home, PageNotFound } from "../app/index";
import useRouteService from "../features/Routes/useRouteService";
import Spotify from "../app/Spotify/Spotify";

export default function AppRoutes() {
  const location = useLocation();
  const { setCurrentRoute } = useRouteService();

  useEffect(() => {
    setCurrentRoute(window.location.pathname);
  }, [location.pathname]);

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/spotify" element={<Spotify />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </AnimatePresence>
  );
}
