import { dispatch } from "../../Store";
import {
  setPopsetlist,
  generatePlaylist,
  setCurrentPopSetlistId,
  setSpotifyAccessToken,
  setSpotifyLoggedIn,
} from "./Spotify.slice";

export default class SpotifyService {
  setPopupSetlist(payload: boolean) {
    dispatch(setPopsetlist(payload));
  }

  generatePlaylist(payload: any[]) {
    dispatch(generatePlaylist(payload));
  }

  setCurrentPopSetlistId(payload: number) {
    dispatch(setCurrentPopSetlistId(payload));
  }

  setSpotifyAccessToken(payload: string) {
    dispatch(setSpotifyAccessToken(payload));
  }
  setSpotifyLoggedIn(payload: boolean) {
    dispatch(setSpotifyLoggedIn(payload));
  }
}
