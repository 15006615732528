import { Field, Form, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import {
  artistId,
  client_id,
  client_secret,
  playlistId,
} from "../../constants";
import useSpotifyService from "../../features/Spotify/useSpotifyService";
import useAppleMusicKit from "../../helpers/apple/useAppleMusicKit";
import useSpotify from "../../helpers/spotify/useSpotify";
import useGlobalService from "../../features/Global/useGlobalService";
import {
  useGlobalSelector,
  useSpotifySelector,
} from "../../features/useSelector";
import MyAsset from "../../asset_strings";

export default function SelectTracks() {
  const { currentPopSetlistId } = useSpotifySelector();
  const { auth, tracks } = useSpotify();
  const [trackList, setTrackList] = useState<any>([]);
  const { generatePlaylist, setCurrentPopSetlistId, setSpotifyAccessToken } =
    useSpotifyService();
  const appleMusicKit = useAppleMusicKit();

  useMemo(async () => {
    const accessToken = await auth.oauth({ client_id, client_secret });
    // const spotify = await tracks.getTopTracks({ accessToken, artistId });
    const spotify = await tracks.getPlaylist({ accessToken, playlistId });

    // prepare the songs from spotify & apple
    const allTracks = await appleMusicKit.prepareTracks(spotify.tracks.items);

    setTrackList(allTracks); // tracks from spotify
    setSpotifyAccessToken(accessToken); // sets an access token
  }, []);

  return (
    <div className={`${currentPopSetlistId === 1 ? "block" : "hidden"}`}>
      <div>
        <h2 className="section-heading text-center">
          Help Me Create My Coachella Playlist
        </h2>

        <div className="flex flex-col md:flex-row gap-[--space-between-items] mt-[--space-between-sections]">
          <div className="w-1/2 mx-auto">
            <img
              className="w-full object-cover"
              src={MyAsset.albumArt}
              alt=""
            />
          </div>

          <div className="w-full">
            {trackList.length ? (
              <Formik
                initialValues={{ checked: [] }}
                onSubmit={(values) => {
                  const lists: any[] = [];

                  trackList.map((track: any) => {
                    const trackUri = track.uri;

                    values.checked.map((value) => {
                      if (value === trackUri) {
                        lists.push(track);
                      }
                    });
                  });

                  generatePlaylist(lists);
                  // change page
                  setCurrentPopSetlistId(2);
                }}
              >
                {() => (
                  <Form className="gap-[--space-between-items] flex flex-col w-full items-center">
                    <div
                      role="group"
                      className="h-[45vh] py-[--space-between-sections] gap-[--space-between-items] overflow-y-scroll grid grid-cols-2 text-left"
                    >
                      {trackList.map((e: any, idx: number) => (
                        <label key={idx} className="input-label flex flex-row">
                          <Field type="checkbox" name="checked" value={e.uri} />
                          <span className="inline-block ml-[1vw] leading-[100%]">
                            {e.name}
                          </span>
                        </label>
                      ))}
                    </div>
                    <div className="w-full">
                      <button className="w-full btn-primary" type="submit">
                        Save Playlist
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <span className="mt-[1vw] text-center block ml-[1vw] leading-[100%]">
                Loading tracks... Please wait
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
