import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import { googleSheetUrl_unreleasedtracks } from "../constants";
import useGlobalService from "../features/Global/useGlobalService";
import { useGlobalSelector } from "../features/useSelector";
import { Success } from "../helpers/google/handleGoogleSheet";
import useGoogleSheet from "../helpers/google/useGoogleSheet";
import InputField from "./Form/InputField";
import Popup from "./Popup";
import MyAsset from "../asset_strings";

export default function UnreleasedTrackPopup() {
  const { setPopUnreleasedTrack } = useGlobalService();
  const { popUnreleasedTrack } = useGlobalSelector();
  const { googleSheet } = useGoogleSheet();

  function playAudio() {
    // play the song
    const audioEl = document.getElementById(
      "unreleased-clip"
    ) as HTMLAudioElement;
    audioEl.currentTime = 0;
    audioEl.play();
  }

  return (
    <Popup
      open={popUnreleasedTrack}
      setOpen={setPopUnreleasedTrack}
      className="p-[--default-space] "
    >
      <div className="w-[100%]">
        <audio id="unreleased-clip" src={MyAsset.unreleasedClip}></audio>

        <h2 className="section-heading text-center">
          Listen to one of my unreleased tracks
        </h2>

        <Formik
          initialValues={{ phone_number: "", contact_email: "" }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            // uploading the form
            const res = await googleSheet.uploadForm(
              { ...values },
              googleSheetUrl_unreleasedtracks
            );

            if (res.result === "success") {
              Success();
              resetForm();

              playAudio();
            } else {
              Error();
            }

            setSubmitting(false);
          }}
        >
          {({ isSubmitting, handleChange, initialValues }) => (
            <Form className="mt-[--space-between-sections] gap-[--space-between-items] flex flex-col w-auto items-center">
              <InputField
                name="phone_number"
                type="text"
                placeholder="Enter phone number"
                required
              />
              <InputField
                name="contact_email"
                type="email"
                placeholder="Enter email"
                required
              />

              <div className="w-full">
                <button className="w-full btn-primary" type="submit">
                  {isSubmitting ? (
                    <span>
                      Submitting{" "}
                      <FontAwesomeIcon
                        icon={faCircle}
                        className="animate-bounce"
                      />
                    </span>
                  ) : (
                    "Listen Now"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Popup>
  );
}
