import $ from "jquery";
import Swal from "sweetalert2";
import { folderId, upload_file_url } from "../../constants";

export function Error() {
  Swal.fire({
    text: "Oops, there something wrong",
    icon: "error",
    iconColor: "#cf152d",
    confirmButtonColor: "#cf152d",
  });
}

export function Success() {
  Swal.fire({
    icon: "success",
    timer: 2000,
    showConfirmButton: false,
  });
}

export type SingleFile = {
  file_name: string;
  file_type: string;
  file_url: string;
};

export interface SingleUploadFileResult {
  files: SingleFile[];
  result: string;
}

export default function handleGoogleSheet() {
  async function uploadFiles(file: File): Promise<SingleUploadFileResult> {
    // format the file
    const body = new FormData();
    body.append("file[]", file);
    body.append("parent_file_id", folderId);

    // submit the file
    const response = await new Promise((res) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", upload_file_url, true);
      xhr.send(body);

      xhr.onload = (e) => {
        const target = e.currentTarget as XMLHttpRequest;
        res(target.response);
      };

      xhr.onerror = (e) => {
        res(undefined);
        console.error("File upload failed.");
      };

      return xhr;
    });

    return JSON.parse(response as string);
  }

  async function uploadForm(values: any, endpoint: string) {
    const { ip } = await _getUsersIp();

    const response = await $.ajax(endpoint, {
      data: {
        ...values,
        date: _getCurrentDate(),
        ip,
      },
    });

    return response;
  }

  function _getCurrentDate() {
    const date = new Date().toLocaleDateString("en-US", {
      timeZone: "America/New_York",
    });
    const time = new Date().toLocaleTimeString("en-US", {
      timeZone: "America/New_York",
    });
    return `${date} - ${time} (GMT-4)`;
  }

  async function _getUsersIp() {
    return await $.ajax("https://api.ipify.org/?format=json");
  }

  return {
    uploadFiles,
    uploadForm,
  };
}
