import { useCallback } from "react";
import { apple_create_playlist, apple_search_url } from "../../constants";

export default function useAppleMusicKit() {
  function kit() {
    return (window as any).musicKit;
  }

  async function prepareTracks(tracks: any) {
    const matchedResult: any = [];

    for (const track of tracks) {
      const searched = await searchSong(track.track.name, track.track);

      // need to match array of searched
      matchedResult.push(matchAppleToSpotify(searched, track.track));
    }

    return matchedResult;
  }

  const searchSong = useCallback(async (songName: string, track: any) => {
    try {
      const url = apple_search_url + songName + "&types=songs";
      const { data } = await kit().api.music(url);

      return data.results.songs.data;
    } catch (error) {
      // console.log("Songs that aren't included: ", songName);
      return track;
    }
  }, []);

  function matchAppleToSpotify(songs: any, track: any) {
    if (!songs) return;

    const artistName = track.artists[0].name;
    const songName = track.name;

    let matched: any = { ...track, appleMusic: {} };

    // songs that aren't found or has some error
    if (!songs.length) {
      return (matched = { ...songs, appleMusic: {} });
    }

    songs.forEach((song: any) => {
      if (
        song.attributes.artistName === artistName &&
        song.attributes.name === songName
      ) {
        matched.appleMusic = song;
      }
    });

    return matched;
  }

  async function createPlaylist(tracks: any, userToken: string) {
    const folderName = "SAINt JHN Coachella";
    const trackIds: any[] = [];

    tracks.forEach((track: any) => {
      if (Object.keys(track.appleMusic).length) {
        trackIds.push({ id: track.appleMusic.id, type: "library-songs" });
      }
    });

    const body = {
      attributes: {
        name: folderName,
        description: "...",
      },
      relationships: {
        tracks: {
          data: trackIds,
        },
      },
    };

    const data = await fetch(apple_create_playlist, {
      method: "POST",
      body: JSON.stringify(body),
      mode: "cors",
      headers: {
        Authorization: `Bearer ${kit().developerToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Music-User-Token": userToken,
      },
    });

    return data;
  }

  return {
    kit: kit(),
    prepareTracks,
    searchSong,
    createPlaylist,
  };
}
