import { appleSlice } from "./Apple/Apple.slice";
import { globalSlice } from "./Global/Global.slice";
import { helloSlice } from "./hello/hello.slice";
import { routesSlice } from "./Routes/Routes.slice";
import { spotifySlice } from "./Spotify/Spotify.slice";

export const rootReducer = {
  HelloWorld: helloSlice.reducer,
  Routes: routesSlice.reducer,
  Global: globalSlice.reducer,
  Spotify: spotifySlice.reducer,
  Apple: appleSlice.reducer,
};
