import { useEffect } from "react";
import useSpotify from "../../helpers/spotify/useSpotify";

export default function Spotify() {
  const { user } = useSpotify();

  useEffect(() => {
    setTimeout(async () => {
      // save the response code to localstorage
      const res = await user.login();
    });
  }, []);

  return <div>Redirecting...</div>;
}
